<template>
  <div class="toggle-container">
    <button
      class="toggle-button"
      :class="{ active: val }"
      @click="$emit('onInput', { name, value: !val })"
    >
      週間
    </button>
    <button
      class="toggle-button"
      :class="{ active: !val }"
      @click="$emit('onInput', { name, value: !val })"
    >
      月間
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: null,
    };
  },
  props: {
    values: Object,
    name: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.toggle-container {
  width: fit-content;
  height: fit-content;
  background-color: $switch_bg_color;
  border: 1px solid $border_color;
  border-radius: 12px;
  margin-top: 5px;
}
.toggle-button {
  width: 60px;
  padding: 5px;
  background-color: $switch_bg_color;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.toggle-button.active {
  background-color: $switch_color;
}
</style>
