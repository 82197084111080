<template>
  <!-- テーブルヘッダー -->
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="title" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isSetting"
        class="mx-2"
        icon
        @click="$emit('openDiaLogSetting', true)"
      >
        <v-icon> mdi-cog </v-icon>
      </v-btn>

      <v-btn v-if="isSearch" class="mx-2" icon @click="openSearch">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        v-if="isDelete"
        class="mx-2"
        icon
        @click="$emit('openRemoveDialog')"
        :disabled="multiRemoveStatus"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        v-if="isInvite"
        class="mx-2"
        color="primary"
        @click="$emit('openInviteDialog')"
        :disabled="multiRemoveStatus"
      >
        招待
      </v-btn>
      <v-btn
        v-if="isCsv"
        class="mx-2"
        color="primary"
        :disabled="disableBtnAdd"
        @click="$emit('openCsvImport')"
      >
        CSV取込
      </v-btn>
      <div v-if="isRepresentative && isRuleRepresentative" class="mt-5 d-flex">
        <div class="flex-column">
          <div class="d-flex">
            <v-btn
              class="mr-5"
              color="warning"
              :disabled="multiRemoveStatus"
              @click="$emit('onReject', true)"
            >
              代表者解除
            </v-btn>
            <v-btn
              class="mr-5"
              color="primary"
              style="width: fit-content"
              :disabled="multiRemoveStatus"
              @click="$emit('onApprove', false)"
            >
              代表者
            </v-btn>
          </div>
          <div class="note">
            ※代表者設定した方は、メールで点検結果の不具合が通知されます。
          </div>
        </div>
      </div>
      <v-btn
        v-if="isAddNew"
        class="mx-2"
        color="primary"
        :disabled="disableBtnAdd"
        @click="$emit('openItemForm')"
        data-testid="add-new-btn"
      >
        {{ addButtonTitle }}
      </v-btn>
      <v-btn
        v-if="isUpdate"
        class="mx-2"
        color="primary"
        @click="$emit('openUpdateForm')"
      >
        編集
      </v-btn>
    </v-toolbar>
    <div v-if="isShowSearch">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    multiRemoveStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    // タイトルを表示
    isTitle: {
      type: Boolean,
      default: true,
    },
    //
    isSetting: {
      type: Boolean,
      default: false,
    },
    // 検索ボタンを表示
    isSearch: {
      type: Boolean,
      default: true,
    },
    // 削除ボタンを表示
    isDelete: {
      type: Boolean,
      default: true,
    },
    // 新規追加ボタンを表示
    isAddNew: {
      type: Boolean,
      default: true,
    },
    // 招待ボタンを表示
    isInvite: {
      type: Boolean,
      default: false,
    },
    isCsv: {
      type: Boolean,
      default: false,
    },
    addButtonTitle: {
      type: String,
      default: "新規追加",
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isRepresentative: {
      type: Boolean,
      default: false,
    },
    isRuleRepresentative: {
      type: Boolean,
      default: false,
    },
    disableBtnAdd: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
  },
};
</script>
