import "./assets/scss/base.scss";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { validationRules } from "./plugins/validationRules";
import VueHead from "vue-head";
import { Store } from "@/store/Store.js";

Vue.config.productionTip = false;
Vue.use(VueHead);

new Vue({
  router,
  Store,
  vuetify,
  validationRules,
  render: (h) => h(App),
  created() {
    //定数をapiから取得
    Store.dispatch("CmnMst/get");
  },
}).$mount("#app");
