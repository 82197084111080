<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <MoviesHeader />
      </template>
      <template #page>
        <Password v-if="!isAuth" @checkAuth="checkAuth" />
        <div v-else class="layout">
          <h2 class="title">マニュアル動画</h2>
          <ul class="main-list">
            <li v-for="(item, index) in videos" :key="index">
              <div @click="toggleSubMenu(index)">
                <v-btn icon class="title-icon">
                  <v-icon>{{ item.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
                </v-btn>
                {{ item.title }}
              </div>
              <div v-show="item.isOpen" class="video-container">
                <video ref="videoPlayer" controls>
                  <source :src="item.source" type="video/mp4" />
                  あなたのブラウザはビデオタグをサポートしていません。
                </video>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="isMobile" class="app-download">
          <h3 class="app-download-title">ダウンロードはこちら</h3>
          <div class="app-download-links">
            <a
              v-if="os === 'iOS'"
              :href="appStoreLink"
              target="_blank"
              class="download-btn"
            >
              <img
                :src="require('@/assets/images/appstore.svg')"
                alt="Download on the App Store"
              />
            </a>
            <a
              v-if="os === 'Android'"
              :href="googlePlayLink"
              target="_blank"
              class="download-btn"
            >
              <img
                :src="require('@/assets/images/googleplay.png')"
                alt="Get it on Google Play"
              />
            </a>
          </div>
          <div class="d-flex flex-column">
            <span v-if="os === 'iOS'"
              >Apple および Apple ロゴは米国その他の国で登録されたApple
              Inc.の商標です。</span
            >
            <span v-if="os === 'iOS'"
              >App Store は Apple Inc. のサービスマークです。</span
            >
          </div>
          <span v-if="os === 'Android'"
            >Google Play および Google Play ロゴは、Google LLC
            の商標です。</span
          >
        </div>
      </template>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import MoviesHeader from "@/components/globalHeader/MoviesHeader";
import Password from "./components/password";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "動画再生" };
    },
  },
  components: {
    DefaultLayout,
    MoviesHeader,
    Password,
  },
  data() {
    return {
      isAuth: false,
      videos: [],
      isMobile: false,
      os: "",
      appStoreLink: "https://apple.co/41pmC36",
      googlePlayLink:
        "https://play.google.com/store/apps/details?id=com.raku2tenken&hl=ja&pli=1",
    };
  },
  created() {
    this.loadVideos();
    this.detectDeviceInfo();
  },
  methods: {
    detectDeviceInfo() {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/iPhone|iPad|iPod/.test(userAgent)) this.os = "iOS";
      else if (/Android/.test(userAgent)) this.os = "Android";
      else if (/Mac/.test(userAgent) && !/iPhone|iPad/.test(userAgent))
        this.os = "macOS";
      else if (/Win/.test(userAgent)) this.os = "Windows";
      else this.os = "Unknown";
      this.isMobile = /iPhone|iPad|iPod|Android/.test(userAgent);
    },
    checkAuth(flag) {
      this.isAuth = flag;
      window.scrollTo(0, 0);
    },
    async loadVideos() {
      try {
        const videoFiles = require.context("@/assets/videos", false, /\.mp4$/);
        this.videos = videoFiles.keys().map((filePath) => {
          const fileName = filePath.split("/").pop().replace(".mp4", "");
          return {
            title: fileName,
            isOpen: false,
            source: videoFiles(filePath),
          };
        });
      } catch (error) {
        console.error("Error loading videos:", error);
      }
    },
    toggleSubMenu(index) {
      this.videos[index].isOpen = !this.videos[index].isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

ul {
  list-style-type: none;
  width: 50%;
  padding: 0;
}

li {
  cursor: pointer;
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid $border_color;
  border-radius: 10px;
  box-shadow: 0 5px 10px $shadow_color;

  .title-icon {
    background-color: $accent_color;
    width: 20px;
    height: 20px !important;

    .v-icon {
      color: $primary_color !important;
      font-size: 20px;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.video-container {
  width: 100%;
  height: auto;
  margin-top: 10px;
  background: $shadow_color;
  border: 2px solid $border_color;
}

video {
  width: 100%;
  height: auto;
}

.app-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;

  .app-download-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .app-download-links {
    display: flex;

    .download-btn {
      width: 150px;
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 768px) {
  ul {
    width: 90%;
  }

  li {
    font-size: 14px;
  }
}
</style>
