<template>
  <div>
    <SingleLayout>
      <v-card max-width="400" outlined>
        <ValidationObserver v-slot="{ invalid }">
          <v-form
            ref="form"
            lazy-validation
            autocomplete="off"
            @submit.prevent="Login"
          >
            <v-list-item>
              <v-list-item-content>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="パスワード"
                  rules="required|password"
                >
                  <v-text-field
                    v-model="formValue.password"
                    :error-messages="errors"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShow ? 'text' : 'password'"
                    dense
                    height="48px"
                    placeholder="パスワード入力してください"
                    @click:append="passwordShow = !passwordShow"
                  ></v-text-field>
                </ValidationProvider>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn color="primary" :disabled="invalid" @click="Login"
                  >動画一覧へ</v-btn
                >
              </v-list-item-content>
            </v-list-item>
          </v-form>
        </ValidationObserver>
      </v-card>
    </SingleLayout>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout";

export default {
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => {
    return {
      formValue: {
        password: "",
      },
      passwordShow: false,
    };
  },
  methods: {
    Login(event) {
      event.preventDefault();
      let password = process.env.VUE_APP_MOVIES_PASSWORD;
      if (this.formValue.password === password) {
        this.$emit("checkAuth", true);
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: "パスワードが違います。",
        });
      }
    },
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    window.addEventListener("keydown", (event) => {
      if (
        event.key === "Enter" &&
        !this.$refs.form.$el.contains(document.activeElement)
      ) {
        this.Login(event);
      }
    });
  },
};
</script>
