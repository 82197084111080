<template>
  <v-menu
    v-model="isShow"
    ref="menu"
    transition="scroll-y-transition"
    offset-y
    :nudge-top="4"
    :nudge-left="25"
    min-width="auto"
    dense
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <SearchFormWrapper>
        <InputText
          name="pickDate"
          placeholder="日付を選択"
          :values="computedDateRange"
          :readonly="true"
          :editable="true"
          innerIcon="mdi-calendar-today"
          @click="isShow = !isShow"
        />
      </SearchFormWrapper>
    </template>
    <div>
      <v-date-picker
        v-model="val"
        locale="ja"
        type="month"
        :color="COLORS.CALENDAR_COLOR"
        :day-format="(val) => new Date(val).getDate()"
        :first-day-of-week="0"
        no-title
        scrollable
        dense
        range
        @change="onInput(val)"
      >
      </v-date-picker>
      <div>
        <v-btn class="btn left-btn" @click="resetDate(new Date())"
          >クリア</v-btn
        >
        <v-btn class="btn right-btn" @click="isShow = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </div>
  </v-menu>
</template>
<script>
import { format, parseISO } from "date-fns";
import { COLORS } from "@/constants/COMMON";

// componets
import InputText from "@/components/forms/elements/InputText";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper.vue";

export default {
  data: () => {
    return {
      COLORS,
      val: [],
      dateString: {
        pickDate: "",
      },
      isShow: false,
    };
  },
  components: {
    InputText,
    SearchFormWrapper,
  },
  props: {
    values: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        let fromDate = newValue[0].inspection_date_from;
        let toDate = newValue[0].inspection_date_to;
        fromDate = format(new Date(fromDate), "yyyy-MM");
        toDate = format(new Date(toDate), "yyyy-MM");
        if (fromDate && toDate) {
          const newDate = [];
          newDate.push(fromDate);
          newDate.push(toDate);
          this.val = newDate;
        }
      },
      { immediate: true, deep: true }
    );
  },
  computed: {
    computedDateRange() {
      if (this.val.length > 0) {
        const fromDate = this.val[0] ? this.val[0] : "";
        const toDate = this.val[1] ? this.val[1] : "";
        let formattedFromDate = "";
        let formattedToDate = "";
        if (fromDate && !fromDate.includes("/")) {
          formattedFromDate = format(parseISO(fromDate), "yyyy/MM");
        } else {
          formattedFromDate = fromDate;
        }
        if (toDate && !toDate.includes("/")) {
          formattedToDate = format(parseISO(toDate), "yyyy/MM");
        } else {
          formattedToDate = toDate;
        }
        this.dateString.pickDate = `${formattedFromDate} 〜 ${formattedToDate}`;
      } else {
        this.dateString.pickDate = "";
      }
      return this.dateString;
    },
  },
  methods: {
    onInput(val) {
      const [fromVal, toVal] = val;
      this.fromVal = format(new Date(fromVal), "yyyy/MM/dd");
      this.toVal = format(new Date(toVal), "yyyy/MM/dd");
      this.$emit("onInput", {
        name: "customMonth",
        value: { fromVal: this.fromVal, toVal: this.toVal },
      });
    },
    resetDate(current_date) {
      let currentDate = format(new Date(current_date), "yyyy/MM/dd");
      this.$emit("onInput", {
        name: "customMonth",
        value: { fromVal: currentDate, toVal: currentDate },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-text-field__slot input {
  width: 170px !important;
  font-size: 16px !important;
  &:hover {
    cursor: pointer;
  }
}
.btn {
  position: absolute;
  top: 0;
  background-color: transparent !important;
  height: 40px !important;
  min-width: 60px !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  color: $page_title_color;
}
.left-btn {
  left: 0;
}
.right-btn {
  right: 0;
}
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 150px;
}
.v-picker ::v-deep .v-btn {
  font-size: 12px !important;
  font-weight: normal !important;
}
::v-deep .v-date-picker-table {
  padding: 0 !important;
  height: fit-content !important;
}
::v-deep .v-date-picker-header {
  left: 55px !important;
  width: 190px !important;
  padding: 0 !important;
  .v-date-picker-header__value {
    font-size: 16px !important;
  }
  .v-date-picker-header__value button {
    padding: 0 !important;
  }
  .v-btn {
    width: fit-content !important;
  }
}
::v-deep .v-text-field__details {
  display: none !important;
}
</style>
