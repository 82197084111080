import Api, { Mock } from "../api";
import company_list from "./mock/company_list.json";
import company_detail from "./mock/company_detail.json";

const COMPANY_BRANCHES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/companies`;
const GET_LIST_URL = `${COMPANY_BRANCHES_BASE_URL}/get_list_companies`;
const GET_LIST_BY_IDS_URL = `${COMPANY_BRANCHES_BASE_URL}/get_list_companies_by_ids`;
const DELETE_URL = `${COMPANY_BRANCHES_BASE_URL}/delete_companies`;
const GET_DETAIL_URL = `${COMPANY_BRANCHES_BASE_URL}/get_company_info`;
const GET_ID_BY_CORPORATE_NUMBER_URL = `${COMPANY_BRANCHES_BASE_URL}/get_company_id_by_corporate_number`;
const CHECK_EXITS_URL = `${COMPANY_BRANCHES_BASE_URL}/check_company_exits`;
const CREATE_URL = `${COMPANY_BRANCHES_BASE_URL}/add_company_info`;
const UPDATE_URL = `${COMPANY_BRANCHES_BASE_URL}/update_company_info`;
const LIST_COMPANIES_HAS_MACHINE = `${COMPANY_BRANCHES_BASE_URL}/get_list_companies_has_machine`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: company_list,
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: company_detail,
  });
  Mock.onGet(new RegExp(`${CHECK_EXITS_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [],
    },
  });
  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(LIST_COMPANIES_HAS_MACHINE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: company_list,
  });
}

export const company = {
  get: (params) => {
    return Api.get(GET_LIST_URL, { params });
  },
  getCompanyByIds: (params) => {
    return Api.get(GET_LIST_BY_IDS_URL, { params });
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getCompanyDetail: async (params) => {
    return Api.get(`${GET_DETAIL_URL}/${params}`);
  },
  getCompanyIdByCorporateNumber: async (params) => {
    return Api.get(`${GET_ID_BY_CORPORATE_NUMBER_URL}/${params}`);
  },
  checkCompanyExits: async (params) => {
    return Api.get(`${CHECK_EXITS_URL}/${params}`);
  },
  post: async (params) => {
    return Api.post(CREATE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_URL, params);
  },
  getListCompaniesHasMachine: () => {
    return Api.get(LIST_COMPANIES_HAS_MACHINE);
  },
};
