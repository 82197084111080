import Vue from "vue";
import Vuex from "vuex";
import { home } from "../../../api/modules/home";

Vue.use(Vuex);

const ENTITY = home;

export const Home = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    machineSitesDetail: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
  },

  actions: {
    async getGraphData({ commit }, payload) {
      const response = await ENTITY.getGraphData(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async getSearchData(_context, payload) {
      return await ENTITY.getGraphData(payload);
    },
    async getListInspecResult(_context, payload) {
      return await ENTITY.getListInspecResult(payload);
    },
    async getInspectionsInfo(_context, payload) {
      return await ENTITY.getInspectionsInfo(payload);
    },
    async getNewMachinesThisMonth(_context, payload) {
      return await ENTITY.getNewMachinesThisMonth(payload);
    },
    async getInspectionResultNG(_context, payload) {
      return await ENTITY.getInspectionResultNG(payload);
    },
    async getCountMonthlyInspecResult(_context, payload) {
      return await ENTITY.getCountMonthlyInspecResult(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
  },
};
