const getTimeToParam = (time) => {
  const _time = time.split(":");
  return {
    h: +_time[0],
    m: +_time[1],
  };
};

const getTimeRange = (start, end) => {
  if (!start || !end) {
    return {
      h: 0,
      m: 0,
      value: "00:00",
    };
  }
  const startTime = getTimeToParam(start);
  const endTime = getTimeToParam(end);
  const startTimeMin = +startTime.h * 60 + +startTime.m;
  const endTimeMin = +endTime.h * 60 + +endTime.m;
  const rangeTimeMin = endTimeMin - startTimeMin;
  const h = Math.floor(rangeTimeMin / 60);
  const m = rangeTimeMin - h * 60;
  return {
    h: h,
    m: m * 60,
    value: `${`${h}`.padStart(2, "0")}:${`${m}`.padStart(2, "0")}`,
  };
};

// 取得日付：日本の日付フォーマット（曜日：有）
const getDateJp = (dateStr) => {
  let date;
  if (dateStr) {
    date = new Date(dateStr);
  } else {
    date = new Date();
  }
  var dayOfWeek = date.getDay(); // 曜日(数値)
  var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]; // 曜日(日本語表記)
  var formattedDay =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    `（${dayOfWeekStr}）`;
  var fmMonthDay =
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "月" +
    ("0" + date.getDate()).slice(-2) +
    "日" +
    `${dayOfWeekStr}曜日`;
  var formattedTime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);

  return { date: formattedDay, time: formattedTime, fmMonthDay: fmMonthDay };
};

// 日付変換：スラッシュ区切りの日付
const fmtSlashDate = (dateStr) => {
  let date;
  if (dateStr) {
    date = new Date(dateStr);
  } else {
    return dateStr;
  }
  var formattedDay =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2);
  var formattedTime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);

  return { date: formattedDay, time: formattedTime };
};

export { getTimeToParam, getTimeRange, getDateJp, fmtSlashDate };
