<template>
  <div>
    <v-app-bar class="portalHeader" dense>
      <div class="portalHeader-title">
        <img class="portalHeader-logo" src="@/assets/rakuraku.png" />
      </div>
      <v-spacer></v-spacer>
      <v-btn text class="login" @click="$router.push('/')"> ログイン </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-toolbar__content {
  background-color: $main_header_bg_color !important;
}

.login {
  color: $secondary_color;
}
</style>
