export const INSPECTIONS_TABLE_LABELS = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "30%",
  },
  {
    text: "管理名",
    value: "machine_field_name",
    align: "left",
    width: "20%",
  },
  {
    text: "点検日時",
    value: "inspection_at",
    align: "left",
    width: "20%",
  },
  {
    text: "点検者",
    value: "inspector",
    align: "left",
    sortable: false,
    width: "30%",
  },
];

export const INSPECTIONS_NG_TABLE_LABELS = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "15%",
  },
  {
    text: "仕様・能力",
    value: "machine_name",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "管理名（呼名）",
    value: "machine_field_name",
    align: "left",
    sortable: false,
    width: "15%",
  },
  {
    text: "点検会社",
    value: "inspector_company",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "点検者名",
    value: "inspector",
    align: "left",
    sortable: false,
    width: "15%",
  },
  {
    text: "点検実施日",
    value: "inspection_at",
    align: "left",
    width: "15%",
  },
  {
    text: "状態",
    value: "result",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "コメント",
    value: "comment",
    align: "left",
    sortable: false,
    width: "10%",
  },
];

export const NEW_MACHINES_TABLE_LABELS = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "25%",
  },
  {
    text: "管理名（呼名）",
    value: "machine_field_name",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "仕様・能力",
    value: "machine_name",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "点検会社",
    align: "left",
    value: "company_name",
    width: "20%",
  },
  {
    text: "登録日",
    align: "left",
    value: "created_at",
    width: "15%",
  },
];
