<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :isAddNew="false"
              :isUpdate="true"
              :isDelete="false"
              :isRepresentative="true"
              :isSearch="false"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isRuleRepresentative="IS_USER_FIELD"
              @onApprove="onRepresentative"
              @onReject="onRepresentative"
              @openUpdateForm="openUpdateForm"
            ></TableHeader>
            <SearchFormWrapper>
              <InputText
                class="search width-small"
                placeholder="氏名"
                name="word"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <SelectWithFilter
                class="search width-middle"
                placeholder="所属会社"
                name="company_id"
                :items="COMPANY_LIST"
                item_text="name"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <TabSelect
                class="search"
                name="role"
                :items="FIELD_USER_ROLE"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <SwitchInput
                class="search"
                name="representative_flag"
                label="代表者のみ表示"
                :values="searchInputs"
                :editable="true"
                @onInput="onChangeSearchInputs"
              />
              <v-btn color="primary" @click="onSearch"> 検索 </v-btn>
            </SearchFormWrapper>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              item-key="id"
            >
              <template v-slot:[`item.role`]="{ item }">
                {{ handleRole(item.role) }}
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="cst-overflow-td">
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:[`item.company_name`]="{ item }">
                <div class="cst-overflow-td">
                  {{ item.company_name }}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div class="cst-overflow-td-email">
                  {{ item.email }}
                </div>
              </template>
              <template v-slot:[`item.representative_flag`]="{ item }">
                {{ handleRepresentative(item.representative_flag) }}
              </template>
              <template v-slot:[`item.flag_check_past`]="{ item }">
                {{ getFlagCheckPast(item.flag_check_past) }}
              </template>
              <template v-slot:[`item.btn_dot`]="{ item }">
                <v-menu v-if="IS_MACHINE_MANAGER" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-item">
                    <SwitchInput
                      name="flag_check_past"
                      label="過去日の点検を許可"
                      :values="item"
                      :editable="true"
                      @onInput="onChangeFlagCheckPast($event, item)"
                    />
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <UpdateUserFieldForm
        @updateChecked="updateChecked"
        @cancel="closeItemForm"
        ref="UpdateUserFieldForm"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        @close="closeRemoveDialog()"
        @yes="onConfirmRepresentative"
        :hasDetails="false"
        :isUnlock="message.flg"
        :isAccept="!message.flg"
        :title="message.title"
        :text="message.text"
      />
    </Popup>

    <Loader :isLoading="isLoading"></Loader>
  </div>
</template>
<script>
// Common and functions
import { Store } from "@/store/Store.js";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";
import {
  FIELD_USER_TABLE_LABELS,
  FIELD_USER_SORT_ITEMS,
  FIELD_USER_ROLE,
  FIELD_USER_ROLE_ARRAY,
  MESSAGE_DIALOG,
  INIT_MESSAGE,
  FLAG_CHECK_PAST,
} from "@/constants/FIELD_USER";
import { HEADER_MENU_ITEMS_FIELD } from "@/constants/GLOBALHEADER";
import { COMPANY_TYPE } from "@/constants/COMMON";

// Components
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/forms/elements/TableHeader";
import Pagination from "@/components/forms/elements/Pagination";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/forms/elements/TableSortWrapper";
import TableSort from "@/components/forms/elements/TableSort";
import Popup from "@/components/common/Popup";
import Loader from "@/components/forms/elements/Loader";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import InputText from "@/components/forms/elements/InputText";
import Label from "@/components/forms/elements/Label";
import TabSelect from "@/components/forms/elements/TabSelect";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import UpdateUserFieldForm from "@/components/forms/fields/fieldUsers/index";

// page_title
const PAGE_TITLE = "ユーザー";

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// STORE
const STORE = "FieldUser";

// Table header label
const TABLE_LABELS = FIELD_USER_TABLE_LABELS;

// Sort element
const SORT_ITEMS = FIELD_USER_SORT_ITEMS;

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "現場ユーザー" };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    Loader,
    InputText,
    Label,
    TabSelect,
    UpdateUserFieldForm,
    SwitchInput,
    SelectWithFilter,
    ConfirmDialog,
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      COMPANY_LIST: [],
      items: [],
      field_id: null,
      isLoading: false,
      selectedItems: [],
      // searchParams
      searchParams: {
        created_at: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
      popups: {
        isShowItemForm: false,
        isShowConfirmDialog: false,
      },
      message: { ...INIT_MESSAGE },
      // searchInputs
      searchInputs: {
        word: null,
        company_id: null,
        role: [],
        representative_flag: 0,
      },
    };
  },

  mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD.USER_FIELDS.id,
    });

    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * set data list table
         */
        this.items = data[0];

        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.state.GlobalHeader.selectedSite,
      async (newValue, oldValue) => {
        if (newValue?.field_id !== oldValue?.field_id) {
          this.checkRepresentative();
          await this.getListCompanies();
          this.getItems();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    FIELD_USER_ROLE() {
      return Object.keys(FIELD_USER_ROLE).map((key) => {
        return FIELD_USER_ROLE[key];
      });
    },

    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },

    loginUser() {
      return Store.state.Login.user;
    },

    IS_USER_FIELD() {
      return Store.getters[`${STORE}/getIsUserField`];
    },

    apiParams() {
      return {
        field_id: this.currentSite.field_id,
        company_id: this.searchParams.company_id,
        user_name: this.searchParams.word,
        role: this.searchParams.role,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        sort_value: this.searchParams.sort,
        representative_flag: this.searchParams.representative_flag,
      };
    },

    IS_MACHINE_MANAGER() {
      return this.currentSite.machine_manager_id == this.loginUser.id;
    },
  },

  methods: {
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.searchParams["currentPage"] = 1;
      this.selectedItems = [];
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * (Common)
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * Open 編集 form
     */
    async openUpdateForm() {
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.getItems();
      this.checkRepresentative();
    },

    /**
     *  (Common)
     * API get data
     */
    async getItems() {
      this.isLoading = true;
      if (this.currentSite.field_id) {
        if (!this.apiParams.sort_value) {
          this.apiParams.sort_value = "";
        }
        await Store.dispatch(`${STORE}/get`, this.apiParams);
      }
      this.isLoading = false;
    },

    /**
     * API get list companies
     */
    async getListCompanies() {
      let fieldApiParams = {
        field_id: this.currentSite.field_id,
      };
      const response0 = await Store.dispatch(`FieldUser/get`, fieldApiParams);
      const { entries } = response0.data.contents;
      let listIds = [];
      if (!response0.hasError) {
        listIds = [...new Set(entries.map((entry) => entry.company_id))];
      }
      let apiParams = { company_type: [COMPANY_TYPE.RENTAL] };
      const response1 = await Store.dispatch(`Company/get`, apiParams);
      if (!response1.hasError) {
        const { entries } = response1.data.contents;
        let ids = [];
        ids.push(...listIds);
        ids.push(...entries.map((item) => item.id));
        const response2 = await Store.dispatch(`Company/getCompanyByIds`, {
          ids,
        });
        if (!response2.hasError) {
          const { entries } = response2.data.contents;
          this.COMPANY_LIST = [...entries];
          this.COMPANY_LIST.unshift({
            id: null,
            name: "",
          });
        }
      }
    },

    /**
     * convert role id -> name
     */
    handleRole(role) {
      return role == FIELD_USER_ROLE_ARRAY[0].value
        ? FIELD_USER_ROLE_ARRAY[0].name
        : FIELD_USER_ROLE_ARRAY[1].name;
    },
    handleRepresentative(representative_flag) {
      return representative_flag == 1 ? "代表者" : "";
    },
    onRepresentative(flg) {
      this.message.flg = flg;
      this.message.warning = flg;
      if (flg) {
        this.message.title = MESSAGE_DIALOG.TITLE_REJECT;
        this.message.title_button = MESSAGE_DIALOG.TITLE_REJECT_BUTTON;
        this.message.text = MESSAGE_DIALOG.TITLE_REJECT_TEXT;
      } else {
        this.message.title = MESSAGE_DIALOG.TITLE_APPROVE;
        this.message.title_button = MESSAGE_DIALOG.TITLE_APPROVE_BUTTON;
        this.message.text = MESSAGE_DIALOG.TITLE_APPROVE_TEXT;
      }
      this.popups.isShowConfirmDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.message = { ...INIT_MESSAGE };
      });
    },
    async onConfirmRepresentative() {
      const user_id = this.selectedItems.map((items) => items.id);
      const params = {
        field_id: this.currentSite.field_id,
        user_id,
        representative_flag: this.message.flg ? 0 : 1,
      };
      const rs = await Store.dispatch(`${STORE}/representative`, params);
      if (!rs.hasError) {
        this.getItems();
        Store.dispatch("Toast/show", {
          status: rs.status_code,
          message: "更新しました",
        });
      } else {
        Store.dispatch("Error/show", {
          status: rs.status_code,
          message: rs.message,
        });
      }
      this.closeRemoveDialog();
    },
    async checkRepresentative() {
      if (this.currentSite.field_id) {
        const params = {
          field_id: this.currentSite.field_id,
          user_id: this.loginUser.id,
        };
        await Store.dispatch(`${STORE}/checkRepresentative`, params);
      }
    },
    updateChecked(ids) {
      this.selectedItems = this.selectedItems.filter((e) => {
        return ids.includes(e.id);
      });
    },
    async onChangeFlagCheckPast({ value }, item) {
      const params = {
        user_field_id: item.user_fields_id,
        flag_check_past: value,
      };
      const result = await Store.dispatch(`${STORE}/updateCheckPast`, params);
      if (!result.hasError) {
        await this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      }
    },
    getFlagCheckPast(id) {
      return id == FLAG_CHECK_PAST.PAST.id
        ? FLAG_CHECK_PAST.PAST.name
        : FLAG_CHECK_PAST.UN_PAST.name;
    },
  },
};
</script>
<style scoped>
.menu-item {
  height: 51px;
  width: 173px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 11px;
}
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20vw;
}
.cst-overflow-td-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15vw;
}
</style>
