import Vue from "vue";
import VueRouter from "vue-router";
import { Store } from "@/store/Store";
import Login from "@/views/login/index.vue";
import Movies from "@/views/movies/movies.vue";
import ResetPassword from "@/views/login/resetPassword/index.vue";
import ResetMail from "@/views/login/resetMail/index.vue";
import MasterSites from "@/views/master/sites/index.vue";
import Companies from "@/views/master/companies/index.vue";
import Users from "@/views/master/users/index.vue";
import Machines from "@/views/master/machines/index.vue";
import Home from "@/views/fields/home/index.vue";
import FieldUsers from "@/views/fields/fieldUsers/index.vue";
import FieldMachines from "@/views/fields/fieldMachines/index.vue";
import Inspections from "@/views/fields/inspections/index.vue";

Vue.use(VueRouter);

const routes = [
  // views - 各画面
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/movies",
    name: "Movies",
    component: Movies,
    meta: { isPublic: true },
  },
  {
    path: "/password_reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { isPublic: true },
  },
  {
    path: "/reset",
    name: "ResetMail",
    component: ResetMail,
    meta: { isPublic: true },
  },
  {
    path: "/master/sites",
    name: "MasterSites",
    component: MasterSites,
    meta: { isPublic: true },
  },
  {
    path: "/master/company",
    name: "Companies",
    component: Companies,
    meta: { isPublic: true },
  },
  {
    path: "/master/users",
    name: "Users",
    component: Users,
    meta: { isPublic: true },
  },
  {
    path: "/master/machines",
    name: "Machines",
    component: Machines,
    meta: { isPublic: true },
  },
  {
    path: "/field/home",
    name: "Home",
    component: Home,
    meta: { isPublic: true },
  },
  {
    path: "/field/users",
    name: "FieldUsers",
    component: FieldUsers,
    meta: { isPublic: true },
  },
  {
    path: "/field/machines",
    name: "FieldMachines",
    component: FieldMachines,
    meta: { isPublic: true },
  },
  {
    path: "/field/inspections",
    name: "Inspections",
    component: Inspections,
    meta: { isPublic: true },
  },
];

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  // パスワードリセット
  if (to.path === "/movies") {
    next();
    return;
  }

  // tokenがない場合はloginに遷移
  let token = Store.getters["Login/getToken"];

  // ログイン画面の場合
  if (to.path === "/") {
    // loginでtokenある場合はポータルに遷移
    if (token) {
      next({ name: "Machines" });
    } else {
      next();
    }
    return;
  }

  // パスワードリセット
  if (to.path === "/password_reset") {
    next();
    return;
  }

  // その他
  if (!token) {
    /**
     * login以外
     */
    next({ name: "Login" });
  } else {
    next();
  }
});

export { routes };
export default router;
