import Api from "../api";

const BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GRAPH_DATA_URL = `${BASE_URL}/get_graph_data`;
const INSPECTION_DATA_URL = `${BASE_URL}/get_home_inspection_result`;
const INSPECTIONS_INFO = `${BASE_URL}/get_inspections_info`;
const NEW_MACHINES = `${BASE_URL}/get_new_machines`;
const INSPECTION_NG_DATA_URL = `${BASE_URL}/get_ng_inspection_result`;
const MONTHLY_INSPECTION = `${BASE_URL}/get_monthly_inspection_result`;

export const home = {
  getGraphData: (params) => {
    return Api.get(GRAPH_DATA_URL, { params });
  },
  getListInspecResult: (params) => {
    return Api.get(INSPECTION_DATA_URL, { params });
  },
  getInspectionsInfo: (params) => {
    return Api.get(INSPECTIONS_INFO, { params });
  },
  getNewMachinesThisMonth: (params) => {
    return Api.get(NEW_MACHINES, { params });
  },
  getInspectionResultNG: (params) => {
    return Api.get(INSPECTION_NG_DATA_URL, { params });
  },
  getCountMonthlyInspecResult: (params) => {
    return Api.get(MONTHLY_INSPECTION, { params });
  },
};
